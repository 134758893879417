import Typography from 'typography'
import fairyGateTheme from 'typography-theme-fairy-gates'

fairyGateTheme.headerFontFamily = ['Indie Flower', 'cursive']
fairyGateTheme.googleFonts = [
  {
    name: 'Indie Flower',
    styles: [
      '300',
      '400',
      '500',
      '600',
      '700'
    ],
  }
]

const typography = new Typography(fairyGateTheme)

export const { scale, rhythm, options } = typography
export default typography
